import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme } from 'styles/theme';

const SearchWrapper = styled.div<{ $size: string; theme: Theme; $variant: 'discrete' | 'underlined' }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;

  ${({ $variant }) =>
    $variant === 'underlined' &&
    `
    @media (${breakpoint.mdMin}) {
      max-width: 50%;
      margin-right: 24px;
    }

    @media (${breakpoint.lgMin}) {
      max-width: 40%;
    }

    @media (${breakpoint.xlMin}) {
      margin-right: 32px;
    }
  `}
`;

export default SearchWrapper;
