import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { tertiaryButton } from '../Base/Button/buttonStyles';

export const SlideFilterWrapper = styled.div<{ $listType?: 'grid' | 'list' | undefined; theme: Theme }>`
  .item {
    ${({ $listType }) => ($listType === 'grid' ? 'margin: 16px 0;' : null)}
    ${({ $listType }) => ($listType === 'grid' ? 'padding-left: 13%;' : null)}
 
    @media (${breakpoint.mdMin}) {
      ${({ $listType }) => ($listType === 'grid' ? 'padding-left: 30%;' : null)}
    }

    @media (${breakpoint.lgMin}) {
      padding: 0 15% 0 15%;
    }

    &:active {
      text-decoration: none;
      color: ${getColor('textPrimary')};
    }

    &.current {
      color: ${getColor('textPrimary')};
      font-weight: 600;
      pointer-events: none;
    }

    &.ranking {
      &.disabled {
        pointer-events: none;
        color: ${getColor('textSecondary')};
      }
    }
  }

  .placeholder {
    ${tertiaryButton}
    display: none;

    &::after {
      border: none;
    }
  }

  .slick-slide:not(.slick-active) {
    position: relative;
    
    button {
      display: none;
    }
    .placeholder {
      display: block;
      position: absolute;
      top: 0;
    }
  }

  .prev {
    margin-left: 1%;
  }

  .next {
    margin-right: 1%;
  }

  .next, .prev {
    ${({ $listType }) => ($listType === 'grid' ? 'padding-left: 4px;' : null)}
    &::before {
      content: '';
    }
  }

  &.year-rank-filter {
    > button {
      display: block;
      margin: 0 auto;
    }

    .year {
      line-height: 19.2px;
      letter-spacing: 0.5px;
      padding: 4px 8px;
      width: auto !important; // Override width: 100% from react-slick

      &.current {
        color: ${getColor('textLink')};
        font-weight: 500;
        background-color: ${getColor('shadeBrandPrimary100')};
      }

      &:hover {
        text-decoration: underline;
      }
    }
    
    .slick-list {
      margin: 0 24px;
    }

    .next, .prev {
      width: 40px;
      height: 20px;

      &.slick-disabled {
        visibility: hidden;
      }
    }
  }
`;

export default SlideFilterWrapper;
