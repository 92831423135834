import { useState } from 'react';
import MinusSquare from 'icons/MinusSquare';
import PlusSquare from 'icons/PlusSquare';
import Button from 'components/Globals/Base/Button';
import Dropdown from 'components/Globals/Base/Dropdown';
import Checkbox from 'components/Globals/Base/Checkbox';
import { SearchFilter } from 'interfaces/components/SearchFilter';
import SearchWButton from 'components/Globals/SearchWButton';
import YearFilter from 'components/Globals/SlideFilter';
import Heading from 'styles/typography/Heading';
import { gridResponsiveSettings } from 'components/Globals/SlideFilter/responsiveSettings';
import toKebabCase from 'lodash.kebabcase';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import { SearchWrapper, FiltersWrapper, FiltersHeader, FiltersContainer } from './FiltersStyles';

export interface FiltersProps {
  filters: SearchFilter[];
  years: string[];
  activeFilters: { [key: string]: string | boolean };
  activeYear: string;
  search: string;
  updateFilters: (type: 'ADD' | 'DELETE', fieldName: string, value: string | boolean) => void;
  resetFilters: () => void;
  updateSearch: (value: string) => void;
  updateYear: (value: string) => Promise<boolean>;
}

const Filters = ({
  filters,
  years,
  activeFilters,
  activeYear,
  search,
  updateFilters,
  resetFilters,
  updateSearch,
  updateYear,
}: FiltersProps) => {
  const [display, setDisplay] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const checkboxFields = filters.filter(
    (item) =>
      item.type === 'checkbox' ||
      (item.options && item.options.length <= 2 && (item.options.includes('yes') || item.options.includes('no'))),
  );

  const selectMenuFields = filters.filter(
    (item) => !checkboxFields.includes(item) && !(item.title === 'Name' || item.title === 'Company'),
  );

  const changeFilterDisplay = () => {
    setDisplay((prevValue) => !prevValue);
  };

  const handleClick = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <SearchWrapper>
        <SearchWButton
          initialValue={search}
          dataCy={RANKING_V2_PAGE.SEARCH}
          onChange={(value) => updateSearch(value)}
          $variant='underlined'
        />
        <YearFilter
          className='year-filter'
          valueList={years}
          dataCy={RANKING_V2_PAGE.YEAR_FILTER}
          selectedValue={activeYear}
          responsiveSettings={gridResponsiveSettings}
          changeSelectedValue={(newYear) => updateYear(newYear)}
        />
      </SearchWrapper>
      <FiltersWrapper>
        {!!filters.length && (
          <>
            <FiltersHeader $display={display}>
              <Heading
                className='title'
                as='h2'
                $fontFamily='graphikCond'
                $size={{ default: 'level4' }}
                data-cy={RANKING_V2_PAGE.FILTER_TITLE}
              >
                <span
                  aria-label='Open and close filters'
                  data-cy={RANKING_V2_PAGE.OPEN_CLOSE_FILTER}
                  role='button'
                  tabIndex={0}
                  onClick={changeFilterDisplay}
                  onKeyDown={changeFilterDisplay}
                >
                  {display ? <PlusSquare /> : <MinusSquare />}
                </span>{' '}
                Filters
              </Heading>
              <Button
                className={`${!Object.keys(activeFilters).length && !search ? 'hidden' : ''}`}
                variant='secondary'
                onClick={resetFilters}
              >
                RESET FILTERS
              </Button>
            </FiltersHeader>
            {display && (
              <>
                <FiltersContainer>
                  {selectMenuFields.map((field) => {
                    const { fieldName, title, options } = field;
                    const optionsList = ['-', ...(options || [])];
                    return (
                      <Dropdown
                        key={title}
                        dataCy={`${toKebabCase(title)}-ddl`}
                        isExpanded={isExpanded}
                        onBtnClick={handleClick}
                        onOptionClick={(value) => updateFilters(value !== '-' ? 'ADD' : 'DELETE', fieldName, value)}
                        list={optionsList}
                        selected={activeFilters[fieldName]?.toString() || ''}
                        size='small'
                        className='dropdown'
                        label={title}
                      />
                    );
                  })}
                </FiltersContainer>
                <FiltersContainer>
                  {checkboxFields.map((field) => {
                    const { fieldName, title } = field;
                    return (
                      <Checkbox
                        className='checkbox'
                        key={`${title}`}
                        dataCy={`${toKebabCase(title)}-cb`}
                        name={title}
                        // @ts-ignore
                        value={activeFilters[fieldName] || false}
                        onChange={(value) => updateFilters(value ? 'ADD' : 'DELETE', fieldName, value)}
                      >
                        {title}
                      </Checkbox>
                    );
                  })}
                </FiltersContainer>
              </>
            )}
          </>
        )}
      </FiltersWrapper>
    </>
  );
};
export default Filters;
