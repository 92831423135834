import Input from 'components/Globals/Base/Input';
import Button from 'components/Globals/Base/Button';
import { useEffect, useState } from 'react';
import SearchWrapper from './SearchStyles';

interface SearchProps {
  initialValue: string;
  placeholder?: string;
  dataCy?: string;
  size?: 'large' | 'small';
  onChange: (value: string) => void;
  $variant?: 'discrete' | 'underlined';
}

export const SearchWButton = ({
  initialValue,
  placeholder = 'Looking for something?',
  size = 'large',
  onChange,
  dataCy = '',
  $variant = 'discrete',
}: SearchProps) => {
  const [search, setSearch] = useState(initialValue);

  useEffect(() => {
    setSearch(initialValue);
  }, [initialValue]);

  return (
    <SearchWrapper
      $size={size}
      $variant={$variant}
    >
      <Input
        placeholder={placeholder}
        value={search}
        data-cy={`${dataCy}-input`}
        size={{ default: size }}
        variant={$variant}
        onChange={setSearch}
        onKeyDown={(e) => (e.key === 'Enter' ? onChange(search) : '')}
      />
      <Button
        onClick={() => onChange(search)}
        variant={size === 'small' ? 'primaryBrand' : 'primary'}
        size={{ default: size }}
        hasRightArrow={false}
        dataCy={`${dataCy}-action`}
      >
        Search
      </Button>
    </SearchWrapper>
  );
};

export default SearchWButton;
