import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const SearchWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  flex-direction: column;

  @media (${breakpoint.mdMin}) {
    flex-direction: row;
  }

  .year-filter {
    max-width: 100%;
    padding: 24px;

    @media (${breakpoint.mdMin}) {
      width: 50%;
      max-width: 50%;
    }

    @media (${breakpoint.lgMin}) {
      width: 60%;
      max-width: 60%;
    }

    .slick-slide {
      text-align: center;
    }
  }
`;

export const FiltersWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfacePrimary')};
  border: 1px solid ${getColor('borderSecondary')};
  margin-bottom: 24px;
  padding: 0 48px;

  @media (${breakpoint.xlMax}) {
    padding: 0 24px;
  }

  @media (${breakpoint.mdMax}) {
    padding: 0 16px;
  }
`;

export const FiltersHeader = styled.div<{ $display: boolean; theme: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 16px 0;

  ${({ $display }) =>
    $display &&
    `
    border-bottom: 1px solid ${getColor('neutralBlack')};
  `}
  .title {
    user-select: none;

    svg {
      height: 18px;
      width: 16px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  button {
    padding: 8px;

    &.hidden {
      visibility: hidden;
    }
  }
`;

export const FiltersContainer = styled.form<{ theme: Theme }>`
  padding: 24px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &:first-of-type {
    border-bottom: 1px solid ${getColor('borderSecondary')};
    padding: 24px 0 16px;

    @media (${breakpoint.mdMin}) {
      padding: 24px 0 4px;
    }
  }
  
  .dropdown, .checkbox {
    flex: 0 0 100%;
   

    @media (${breakpoint.mdMin}) {
      flex: 0 0 calc(50% - 12px);
      margin-right: 12px;
    }

    @media (${breakpoint.xlMin}) {
      flex: 0 1 calc(25% - 36px);
      margin-right: 36px;
    }
    
    select {
      width: 100%;
      height: 42px;
      padding: 8px 16px;
      font-weight: 600;
    }

    label {
      font-weight: 600;
    }
  }

  .checkbox {
    margin-bottom: 8px;
            
    @media (${breakpoint.mdMin}) {
      margin-bottom: 16px;
    }
  }

  .dropdown {
    margin-bottom: 8px;

    @media (${breakpoint.mdMin}) {
      margin-bottom: 20px;
    }

    label {
      text-transform: uppercase;
    }
  }
`;
