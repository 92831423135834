import styled from 'styled-components';
import { breakpoint, overflowLayout } from 'styles/globals';
import { getBodyStyle, Theme } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  ${overflowLayout('48px', '48px')};
  padding: 0 24px;
`;

export const Title = styled(Heading)`
  margin: 24px 0;

  @media (${breakpoint.lgMin}) {
    margin: 48px 24px;
  }

  @media (${breakpoint.xxlMin}) {
    margin: 48px 48px;
  }
`;
export const ResultText = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' })};
  margin-bottom: 16px;

  &.bold {
    font-weight: bold;
  }
`;
export default Wrapper;
