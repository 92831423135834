import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FranchiseListSearch } from 'interfaces/content/Ranking';
import RankingSearchTable from 'components/Ranking/SearchTable';
import Filters from 'components/Ranking/Filters';
import { changeRoute } from 'components/Globals/Base/Link';
import ErrorBoundary from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import { AppErrorSeverity } from 'utils/log/constants/errorSeverity';
import { Title } from './RankingSearchPageStyles';
import * as S from './RankingSearchPageStyles';

export interface RankingsProps {
  franchiseList: FranchiseListSearch;
  initialFilters: { [key: string]: string | boolean };
  years: string[];
  initialYear: string;
  initialSearch: string;
  showTitle?: boolean;
  initialRowCount?: number;
}

const RankingSearchPage: FC<RankingsProps> = ({
  franchiseList,
  initialFilters,
  years,
  initialYear,
  initialSearch,
  showTitle = true,
  initialRowCount,
}) => {
  const router = useRouter();
  const {
    items: initialItems,
    sortableFields: initialSortableFields,
    searchFields: initialSearchFields,
  } = franchiseList;

  const [currentYearItems, setCurrentYearItems] = useState(initialItems);
  const [items, setItems] = useState(initialItems);
  const [sortableFields, setSortableFields] = useState(initialSortableFields);
  const [searchFields, setSearchFields] = useState(initialSearchFields);

  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [search, setSearch] = useState(initialSearch);
  const [year, setYear] = useState(initialYear);
  const isSearchPage = router.route.split('/').includes('search');
  const [currentFranchiseList, setCurrentFranchiseList] = useState(franchiseList);

  const updateQueryParam = (type: 'ADD' | 'DELETE', name: string, value: string | null) => {
    if (type === 'DELETE') {
      delete router.query[name];
    } else {
      router.query[name] = value as string;
    }
    // eslint-disable-next-line no-void
    void router.push(router, undefined, { shallow: true });
  };

  const resetQueryParam = () => {
    const path = router.asPath.split('?')[0];
    // eslint-disable-next-line no-void
    void router.push(path, undefined, { shallow: true });
  };

  const updateFilters = (type: 'ADD' | 'DELETE', name: string, value: string | boolean) => {
    setActiveFilters((prevActive) => {
      if (type === 'ADD') {
        return { ...prevActive, [name]: value };
      }
      // eslint-disable-next-line no-param-reassign
      delete prevActive[name];
      return { ...prevActive };
    });
    updateQueryParam(type, name, value as string);
  };

  const resetFilters = () => {
    setActiveFilters({});
    setSearch('');
    resetQueryParam();
  };

  const updateSearch = (value: string) => {
    setSearch(value);
    updateQueryParam(value === '' ? 'DELETE' : 'ADD', 'Name', value);
  };

  const updateYear = async (value: string) => {
    if (isSearchPage) {
      await changeRoute(`/ranking/${franchiseList.slug}/${value}/search/`)
        .then((res) => {
          setYear(value);
          setSearch('');
          setActiveFilters({});
          return res;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setYear(value);

      return true;
    }
    return false;
  };

  useEffect(() => {
    const activeFilterNames = Object.keys(activeFilters);
    const filteredItems = currentYearItems?.filter((item) => {
      let shouldDisplay = true;
      activeFilterNames.forEach((activeFilterFieldName) => {
        const activeFilterValue =
          typeof activeFilters[activeFilterFieldName] === 'string' ? activeFilters[activeFilterFieldName] : 'yes';
        // eslint-disable-next-line max-len
        const activeFilterIndex = searchFields.findIndex((field) => field.fieldName === activeFilterFieldName);
        if (activeFilterIndex === -1) {
          shouldDisplay = false;
        } else {
          const activeFilterName = searchFields[activeFilterIndex].title;
          if (item.data[activeFilterName] !== activeFilterValue) shouldDisplay = false;
        }
      });
      if (!item.name.toLowerCase().includes(search.toLowerCase())) {
        shouldDisplay = false;
      }
      return shouldDisplay;
    });
    setItems(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, search]);

  useEffect(() => {
    setSearch(initialSearch);
  }, [initialSearch]);

  useEffect(() => {
    setActiveFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    setItems(currentFranchiseList.items);
    setSortableFields(currentFranchiseList.sortableFields);
    setSearchFields(currentFranchiseList.searchFields);
  }, [franchiseList, currentFranchiseList]);

  useEffect(() => {
    if (!isSearchPage) {
      const getNewSearchYear = async () => {
        try {
          const response = await fetch(`/api/getRankingSearchYear/${franchiseList.slug}/${year}`);
          if (response.ok) {
            const updatedList = (await response.json()) as FranchiseListSearch;
            setCurrentFranchiseList(updatedList);
            setCurrentYearItems(updatedList.items);
            resetFilters();
          }
        } catch (error) {
          console.error(error);
        }
      };

      getNewSearchYear().catch(console.error);
    }
  }, [year]);

  return (
    <S.Wrapper className='franchise-content'>
      <ErrorBoundary fragment={UIFragments.RANKING_SEARCH_PAGE}>
        {showTitle && (
          <Title
            as='h1'
            $fontFamily='graphikCond'
            $size={{ default: 'level2', lgMin: 'xlarge', mdMin: 'large' }}
          >
            {currentFranchiseList.title}
          </Title>
        )}
      </ErrorBoundary>
      <ErrorBoundary
        fragment={UIFragments.RANKING_SEARCH_PAGE}
        severity={AppErrorSeverity.HIGH}
      >
        {searchFields && (
          <Filters
            filters={searchFields}
            years={years}
            activeFilters={activeFilters}
            activeYear={year}
            search={search}
            updateFilters={updateFilters}
            resetFilters={resetFilters}
            updateSearch={updateSearch}
            updateYear={updateYear}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary
        fragment={UIFragments.RANKING_SEARCH_PAGE}
        severity={AppErrorSeverity.HIGH}
      >
        {items?.length > 0 ? (
          <RankingSearchTable
            items={items}
            sortableFields={sortableFields}
            initialRowCount={initialRowCount}
          />
        ) : (
          <S.ResultText data-cy='no-results'>
            No results found matching your search criteria. Please search again.
          </S.ResultText>
        )}
      </ErrorBoundary>
    </S.Wrapper>
  );
};

export default RankingSearchPage;
