export const lettersSettings = [
  {
    breakpoint: 2560,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 17,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 14,
    },
  },
  {
    breakpoint: 820,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 10,
    },
  },
  {
    breakpoint: 400,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 6,
    },
  },
];

export const rankingResponsiveSettings = [
  {
    breakpoint: 3840,
    settings: {
      slidesToScroll: 6,
      slidesToShow: 18,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 14,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 10,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 375,
    settings: {
      slidesToScroll: 1,
      slidesToShow: 3,
    },
  },
];

export const listResponsiveSettings = [
  {
    breakpoint: 2560,
    settings: {
      slidesToScroll: 6,
      slidesToShow: 8,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 12,
      slidesToShow: 12,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 425,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 320,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
];

export const gridResponsiveSettings = [
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 7,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 4,
    },
  },
];
